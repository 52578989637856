@import url("https://fonts.googleapis.com/css2?family=Alata&family=DM+Sans:opsz@9..40&display=swap");

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

/* .popup-content {
    background: white;
    padding: 20px;
    border-radius: 4px;
} */
.popup-content {
  max-width: 95%;
  max-height: 90%;
  overflow: auto;
  background: white;
  padding: 1 5px;
  border-radius: 4px;
  min-width: 25%;
}

.mobileSubscriptionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
}

.mobileSubscriptionContainer > p {
  font-family: Poppins;

  line-height: normal;
  text-align: center;
}

.mobileSubscriptionContainer > h1 {
  font-family: Poppins;

  line-height: normal;
  text-align: center;
}

.mobileContainer {
  background-color: white;
  color: black;
  padding: 15px;
  border-radius: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  justify-content: center;
}

.mobileContainer > p {
  color: black;
  margin: 0;
}

.priceContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
  margin-top: 35px;
}

.priceContainerBox2 {
  border-radius: 12px;
  border: 1px solid #8d96ff;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  height: 139px;
}

.subscription_caraousel {
  border-radius: 100% !important;
}

.subscription_caraousel li {
  margin: 0px !important;
}

.subscription_caraousel li button {
  width: 6px !important;
  height: 6px !important;
  border-radius: 100% !important;
  opacity: 1 !important;
}

.subscription_caraousel li.slick-active {
  width: 15px !important;
}

.subscription_caraousel li.slick-active button {
  width: 7px;
  height: 7px;
  margin: 0px !important;
  border-radius: 100%;
}

.subscription_caraousel .slick-dots li.slick-active button {
  background: rgb(102, 109, 211) !important;
  opacity: 1;
}

.ant-carousel {
  width: 100%;
}

.subscription_caraousel {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.ant-carousel .slick-dots-bottom {
  bottom: -20px !important;
}

.custom-spinner .ant-spin-dot-item {
  background-color: rgb(113, 122, 245);
}

.chatNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  /* border-bottom: 0.7px solid #d6cfcf; */
}

.chatNavBtn {
  width: auto;
  background-color: #8d96ff;
  color: white;
  letter-spacing: 0.5px;
  font-weight: 400;
  margin-right: 10px;
  cursor: pointer;
}

.chatNavContainer {
  display: flex;
  flex-wrap: wrap;
}

.chatNav1 {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;
  /* border: 2px solid red; */
  /* background-image: url("/new/chatNavbg.png"); */
}

.chatNav2 {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;
  /* border: 2px solid rgb(3, 126, 106); */
}

.chatnav2Inner {
  padding: 0 40px;
  background-color: #f0f1ff;
  height: auto;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  /* border-radius: 28px; */
  margin-top: 35px;
  border-top-left-radius: 28px;
  border-top-right-radius: 28px;
  justify-content: space-between;
  padding-bottom: 25px;
}

.chatLoginBtn {
  margin-right: 15px;
  width: auto;
  background-color: transparent;
  border: 2px solid #8d96ff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  padding: 0 20px;
  cursor: pointer;
}

.message {
  width: 60%;
  padding: 10px;
  /* margin: 10px; */
  border-radius: 10px;
}

.message > p {
  color: black;
  font-weight: 300;
  line-height: normal;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

/* .icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 5px;
    background-size: contain;
    background-repeat: no-repeat;
}

.icon.user {
    background-image: url('path-to-your-icon/user-icon.png');
}

.icon.receiver {
    background-image: url('path-to-your-icon/receiver-icon.png');
} */

.message-left {
  align-self: flex-start;
  /* background-color: #DCF8C6; */
  display: flex;
  align-items: center;
  width: auto;
  /* width: max-content; */
}

.message-right {
  align-self: flex-end;
  /* background-color: #FFFFFF; */
  display: flex;
  align-items: center;
  width: max-content;
  /* border-top-left-radius: 28px; */
}

.message-left > p {
  border-top-right-radius: 28px;
  border-bottom-right-radius: 28px;
  border-bottom-left-radius: 28px;
}

.message-right > p {
  border-top-left-radius: 28px;
  border-bottom-right-radius: 28px;
  border-bottom-left-radius: 28px;
}

.chatInp:focus {
  /* background: #8d96ff; */
  outline: none;
  border: 2px solid #8d96ff;
}

.barsBtn,
.barsBtn:after,
.barsBtn:before {
  display: block;
  width: 20px;
  height: 3px;
  background: black;
  position: relative;
}

.barsBtn:after,
.barsBtn:before {
  content: "";
  position: absolute;
  left: 0;
}

.barsBtn:before {
  top: -7px;
}

.barsBtn:after {
  bottom: -7.8px;
  height: 3.1px;
}

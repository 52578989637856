.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

* {
  font-family: "Poppins";
}

*:focus {
  /* outline: #8d96ff; */
  border-color: #8d96ff !important;
}

.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading .ant-spin {
  color: #3e79f7;
}

.steps-content {
  min-height: 250px;
  margin-top: 20px;
  padding: 20px;
  background-color: #fafafa;
  border: 1px dashed #e9e9e9;
  border-radius: 5px;
}

.steps-action {
  margin-top: 20px;
  text-align: center;
}

.steps-action .btn {
  margin-right: 10px;
  width: 200px;
}

/* index.css */

/* Regular */
@font-face {
  font-family: "Poppins";
  src: url("../src/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Bold */
@font-face {
  font-family: "Poppins";
  src: url("../src/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

/* Italic */
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins/Poppins-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

/* Semi-Bold */
@font-face {
  font-family: "Poppins";
  src: url("/src/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  /* Semi-Bold weight */
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("/src/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  /* Medium weight */
  font-style: normal;
}

/* Add more variations as needed */

.ant-layout {
  background-color: white !important;
}

.ant-picker-header {
  width: max-content;
  /* padding: 5px; */
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background-color: #8d96ff;
  color: white;
  border: none;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  /* background-color: #8D96FF; */
  color: #8d96ff;
  border: none;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: white;
  border-color: #8d96ff;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #8d96ff;
}

/* body {
  height: 100%;
}

#root {
  height: 100%;
}

.App {
  height: 100%;
} */

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background-color: #8d96ff;
  border: none;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #8d96ff;
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #8d96ff;
}

.custom-modal .ant-modal-content {
  border-radius: 24px;
}

.custom-modal .ant-modal-body {
  border-radius: 24px;
}

/* WebKit browsers */
.custom-modal .ant-modal-body::-webkit-scrollbar {
  width: 8px;
}

.custom-modal .ant-modal-body::-webkit-scrollbar-track {
  background: transparent;
}

.custom-modal .ant-modal-body::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  /* Semi-transparent scrollbar thumb */
  border-radius: 4px;
}

/* Firefox */
.custom-modal .ant-modal-body {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

p {
  color: black;
  font-weight: 400;
  margin: 0;
}

.active-menu-item {
  /* border-bottom: 2px solid black; */
  text-decoration: underline;
  font-weight: 600;
}

/* WebKit browsers */
.custom-steps ::-webkit-scrollbar {
  width: 8px;
}

.custom-steps ::-webkit-scrollbar-track {
  background: transparent;
}

.custom-steps ::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  /* Semi-transparent scrollbar thumb */
  border-radius: 4px;
}

/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap'); */

/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
} */

body {
  height: 100%;
}

.subscription_container {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
  align-items: center;
  z-index: 1;
  flex-direction: column;
  border: 1px solid #ddd1d1;
  margin: 45px 83px;
  padding: 30px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
  border-radius: 60px;
  /* height: 100%; */
}

p {
  font-weight: 600;
}

.sub_part1 {
  display: flex;
  /* width: 70%; */
}

.sub_heading {
  color: black;
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 17.572px;
  margin-bottom: 50px;
}

.sub_para {
  color: black;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.125px;
}

.sub_box_price {
  /* font-size: 45px; */
  /* font-weight: bolder; */
  text-shadow: rgb(160, 154, 154) 10px 8px 2px;

  /* color: rgb(100, 100, 100); */
  color: #646464;
  font-family: Poppins;
  font-size: 62px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.sub_box_currencySymbol {
  text-shadow: rgb(160, 154, 154) 7px 3px 2px;
  color: rgb(100, 100, 100);
  font-size: 28px;
  font-weight: 600;
  font-family: "Poppins";
}

.sub_box_month {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  color: #646464;
}

.white_text {
  color: white !important;
}

.sub_box1 {
  /* border: 2px solid red; */
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub_box2 {
  /* border: 2px solid red; */
  /* width: 70%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.sub_box3 {
  /* border: 2px solid red; */
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub_part2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  /* width: 70%; */
  background: #fff;
  border: 1px solid #8d96ff;
  border-radius: 32px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  margin: 25px 0;
  /* padding: 0 20px; */
  /* height: 425px; */
  max-width: 90%;
}

.monthly_plan_box {
  grid-area: 1 / 1 / 2 / 2;
  /* border: 2px solid green; */
  color: #646464;
  padding: 15px 18px;
}

.quaterly_plan_box {
  grid-area: 1 / 2 / 2 / 3;
  background-color: #757efe;
  color: white;
  color: #646464;
  padding: 29px 16px;
  /* padding: 30px; */
  height: 110%;
  position: relative;
  top: -29px;
  border-radius: 39px;
  /* width: 384px; */
}

.yearly_plan_box {
  grid-area: 1 / 3 / 2 / 4;
  /* border: 2px solid green; */
  padding: 15px 18px;
  color: #646464;
}

.plan-desc {
  color: black;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.plan-desc > p {
  margin: 0;
  color: black;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.activate_btn {
  background-color: white;
  border: 1px solid #757efe;
  padding: 10px 22px;
  /* margin-top: 40px; */
  border-radius: 25px;
  font-size: 20px;
  width: auto;
  color: black;
  cursor: pointer;
  font-family: "Poppins";
  font-weight: 500;
  position: relative;
  top: 15px;
}

/* 
.paypalBtnDesign {
  margin-top: 45px;
  width: 60%;
  margin: 45px auto;
} */

.plan_hr {
  margin: 5px 0;
  height: 1px;
  border: none;
  background: -webkit-gradient(
    linear,
    0 0,
    100% 0,
    from(transparent),
    to(transparent),
    color-stop(50%, black)
  );
}

/* .plan_quater {
  margin: 5px 0;
  height: 1px;
  border: none;
  background: -webkit-gradient(linear,
      0 0,
      100% 0,
      from(transparent),
      to(transparent),
      color-stop(50%, white));
} */

.popular_btn {
  /* position: relative;
  top: -45px; */
  /* padding: 6px 15px; */
  color: black;
  background-color: #fec12d;
  border: none;
  border-radius: 25px;
  font-weight: bold;
  letter-spacing: 0.5px;
  /* width: auto; */
  width: 200px;
  line-height: normal;
  font-size: 18px;
}

.sub_part3 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.sub_part4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 15px 0 0 0;
}

.sub_part4 > p {
  margin: 0px;
}

.no-border-button {
  border: none !important;
}

.part3 {
  display: flex;
  align-items: flex-start;
  margin: 35px 140px;
}

/* 
@media screen and (max-width:1447px) {
  .part3 {
    margin-top: 85px;
  }

}

@media screen and (max-width:1400px) {
  .sub_part2 {
    height: 485px;
  }

  .activate_btn {
    top: 22px;
  }
}

@media screen and (max-width:1275px) {
  .sub_part2 {
    height: 485px;
  }

  .activate_btn {
    top: 22px;
  }

  .sub_box_month {
    font-size: 16px;
  }
}

@media screen and (max-width:1225px) {
  .sub_part2 {
    height: 550px;
  }

  .activate_btn {
    top: 31px;
  }

  .yearly_activateBtn {
    top: 50px;
  }
} */

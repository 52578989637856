@import url(https://fonts.googleapis.com/css2?family=Alata&family=DM+Sans:opsz@9..40&display=swap);
.nav-icon >svg{
  height: 20px;
  width: 20px;
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

/* .popup-content {
    background: white;
    padding: 20px;
    border-radius: 4px;
} */
.popup-content {
  max-width: 95%;
  max-height: 90%;
  overflow: auto;
  background: white;
  padding: 1 5px;
  border-radius: 4px;
  min-width: 25%;
}

.mobileSubscriptionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
}

.mobileSubscriptionContainer > p {
  font-family: Poppins;

  line-height: normal;
  text-align: center;
}

.mobileSubscriptionContainer > h1 {
  font-family: Poppins;

  line-height: normal;
  text-align: center;
}

.mobileContainer {
  background-color: white;
  color: black;
  padding: 15px;
  border-radius: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  justify-content: center;
}

.mobileContainer > p {
  color: black;
  margin: 0;
}

.priceContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
  margin-top: 35px;
}

.priceContainerBox2 {
  border-radius: 12px;
  border: 1px solid #8d96ff;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  height: 139px;
}

.subscription_caraousel {
  border-radius: 100% !important;
}

.subscription_caraousel li {
  margin: 0px !important;
}

.subscription_caraousel li button {
  width: 6px !important;
  height: 6px !important;
  border-radius: 100% !important;
  opacity: 1 !important;
}

.subscription_caraousel li.slick-active {
  width: 15px !important;
}

.subscription_caraousel li.slick-active button {
  width: 7px;
  height: 7px;
  margin: 0px !important;
  border-radius: 100%;
}

.subscription_caraousel .slick-dots li.slick-active button {
  background: rgb(102, 109, 211) !important;
  opacity: 1;
}

.ant-carousel {
  width: 100%;
}

.subscription_caraousel {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.ant-carousel .slick-dots-bottom {
  bottom: -20px !important;
}

.custom-spinner .ant-spin-dot-item {
  background-color: rgb(113, 122, 245);
}

/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap'); */

/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
} */

body {
  height: 100%;
}

.subscription_container {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
  align-items: center;
  z-index: 1;
  flex-direction: column;
  border: 1px solid #ddd1d1;
  margin: 45px 83px;
  padding: 30px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
  border-radius: 60px;
  /* height: 100%; */
}

p {
  font-weight: 600;
}

.sub_part1 {
  display: flex;
  /* width: 70%; */
}

.sub_heading {
  color: black;
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 17.572px;
  margin-bottom: 50px;
}

.sub_para {
  color: black;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.125px;
}

.sub_box_price {
  /* font-size: 45px; */
  /* font-weight: bolder; */
  text-shadow: rgb(160, 154, 154) 10px 8px 2px;

  /* color: rgb(100, 100, 100); */
  color: #646464;
  font-family: Poppins;
  font-size: 62px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.sub_box_currencySymbol {
  text-shadow: rgb(160, 154, 154) 7px 3px 2px;
  color: rgb(100, 100, 100);
  font-size: 28px;
  font-weight: 600;
  font-family: "Poppins";
}

.sub_box_month {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  color: #646464;
}

.white_text {
  color: white !important;
}

.sub_box1 {
  /* border: 2px solid red; */
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub_box2 {
  /* border: 2px solid red; */
  /* width: 70%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.sub_box3 {
  /* border: 2px solid red; */
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub_part2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  /* width: 70%; */
  background: #fff;
  border: 1px solid #8d96ff;
  border-radius: 32px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  margin: 25px 0;
  /* padding: 0 20px; */
  /* height: 425px; */
  max-width: 90%;
}

.monthly_plan_box {
  grid-area: 1 / 1 / 2 / 2;
  /* border: 2px solid green; */
  color: #646464;
  padding: 15px 18px;
}

.quaterly_plan_box {
  grid-area: 1 / 2 / 2 / 3;
  background-color: #757efe;
  color: white;
  color: #646464;
  padding: 29px 16px;
  /* padding: 30px; */
  height: 110%;
  position: relative;
  top: -29px;
  border-radius: 39px;
  /* width: 384px; */
}

.yearly_plan_box {
  grid-area: 1 / 3 / 2 / 4;
  /* border: 2px solid green; */
  padding: 15px 18px;
  color: #646464;
}

.plan-desc {
  color: black;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.plan-desc > p {
  margin: 0;
  color: black;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.activate_btn {
  background-color: white;
  border: 1px solid #757efe;
  padding: 10px 22px;
  /* margin-top: 40px; */
  border-radius: 25px;
  font-size: 20px;
  width: auto;
  color: black;
  cursor: pointer;
  font-family: "Poppins";
  font-weight: 500;
  position: relative;
  top: 15px;
}

/* 
.paypalBtnDesign {
  margin-top: 45px;
  width: 60%;
  margin: 45px auto;
} */

.plan_hr {
  margin: 5px 0;
  height: 1px;
  border: none;
  background: -webkit-gradient(
    linear,
    0 0,
    100% 0,
    from(transparent),
    to(transparent),
    color-stop(50%, black)
  );
}

/* .plan_quater {
  margin: 5px 0;
  height: 1px;
  border: none;
  background: -webkit-gradient(linear,
      0 0,
      100% 0,
      from(transparent),
      to(transparent),
      color-stop(50%, white));
} */

.popular_btn {
  /* position: relative;
  top: -45px; */
  /* padding: 6px 15px; */
  color: black;
  background-color: #fec12d;
  border: none;
  border-radius: 25px;
  font-weight: bold;
  letter-spacing: 0.5px;
  /* width: auto; */
  width: 200px;
  line-height: normal;
  font-size: 18px;
}

.sub_part3 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.sub_part4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 15px 0 0 0;
}

.sub_part4 > p {
  margin: 0px;
}

.no-border-button {
  border: none !important;
}

.part3 {
  display: flex;
  align-items: flex-start;
  margin: 35px 140px;
}

/* 
@media screen and (max-width:1447px) {
  .part3 {
    margin-top: 85px;
  }

}

@media screen and (max-width:1400px) {
  .sub_part2 {
    height: 485px;
  }

  .activate_btn {
    top: 22px;
  }
}

@media screen and (max-width:1275px) {
  .sub_part2 {
    height: 485px;
  }

  .activate_btn {
    top: 22px;
  }

  .sub_box_month {
    font-size: 16px;
  }
}

@media screen and (max-width:1225px) {
  .sub_part2 {
    height: 550px;
  }

  .activate_btn {
    top: 31px;
  }

  .yearly_activateBtn {
    top: 50px;
  }
} */

/* .set-width {
    max-width: 100% !important;
    max-height: 100% !important;
    border-radius: 0px !important;
} */

.chatNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 10px; */
  border-bottom: 0.7px solid #d6cfcf;
}

.chatNavBtn {
  width: auto;
  background-color: #8d96ff;
  color: white;
  letter-spacing: 0.5px;
  font-weight: 400;
  margin-right: 10px;
  cursor: pointer;
}

.chatNavContainer {
  display: flex;
  flex-wrap: wrap;
}

.chatNav1 {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;
  /* border: 2px solid red; */
  /* background-image: url("/new/chatNavbg.png"); */
  padding: 0 40px;
}

.chatNav2 {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;
  /* border: 2px solid rgb(3, 126, 106); */
}

.chatnav2Inner {
  padding: 0 40px;
  background-color: #f0f1ff;
  height: auto;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  /* border-radius: 28px; */
  margin-top: 35px;
  border-top-left-radius: 28px;
  border-top-right-radius: 28px;
  justify-content: space-between;
  padding-bottom: 25px;
}

.chatLoginBtn {
  margin-right: 15px;
  width: auto;
  background-color: transparent;
  border: 2px solid #8d96ff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  padding: 0 20px;
  cursor: pointer;
}

.ant-form-item {
  margin-bottom: 0px !important;
}

.message {
  width: 60%;
  padding: 10px;
  /* margin: 10px; */
  border-radius: 10px;
}

.message > p {
  color: black;
  font-weight: 300;
  line-height: normal;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

/* .icon {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 5px;
      background-size: contain;
      background-repeat: no-repeat;
  }
  
  .icon.user {
      background-image: url('path-to-your-icon/user-icon.png');
  }
  
  .icon.receiver {
      background-image: url('path-to-your-icon/receiver-icon.png');
  } */

.message-left {
  align-self: flex-start;
  /* background-color: #DCF8C6; */
  display: flex;
  align-items: center;
  width: auto;
  /* width: fit-content; */
}

.message-right {
  align-self: flex-end;
  /* background-color: #FFFFFF; */
  display: flex;
  align-items: center;
  width: -webkit-max-content;
  width: max-content;
  /* border-top-left-radius: 28px; */
}

.message-left > div {
  border-top-right-radius: 28px;
  border-bottom-right-radius: 28px;
  border-bottom-left-radius: 28px;
}

.message-right > div {
  border-top-left-radius: 28px;
  border-bottom-right-radius: 28px;
  border-bottom-left-radius: 28px;
}

.chatInp:focus {
  /* background: #8d96ff; */
  outline: none;
  border: 2px solid #8d96ff;
}

.bouncing-loader {
  display: flex;
  justify-content: center;
}

.bouncing-loader > div {
  /* width: 16px;
    height: 16px;
    margin: 3px 6px;
    border-radius: 50%;
    background-color: #a3a1a1;
    opacity: 1;
    animation: bouncing-loader 0.6s infinite alternate; */
  width: 7px;
  height: 10px;
  margin: 0px 2px;
  border-radius: 50%;
  background-color: #a3a1a1;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-12px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}

.menuBar {
  /* padding: 0 20px 0 10px; */
  overflow: auto;
  /* box-shadow: 0 0 30px #f3f1f1; */
}

.ant-drawer-close {
  width: 10% !important;
  /* This might not work as expected */
  font-size: 16px !important;
  /* Adjust this value as needed */
}

.logo {
  width: 300px;
  float: left;
}

.logo a {
  display: inline-block;
  font-size: 20px;
  padding: 19px 20px;
  text-transform: capitalize;
}

.menuCon {
  width: calc(100% - 300px);
  float: left;
}

.menuCon .ant-menu-item {
  padding: 0px 5px;
}

.menuCon .ant-menu-submenu-title {
  padding: 10px 20px;
}

.menuCon .ant-menu-item a,
.menuCon .ant-menu-submenu-title a {
  padding: 0px 0px;
}

.menuCon .ant-menu-horizontal {
  border-bottom: none;
}

.menuCon .leftMenu {
  float: left;
}

.menuWidth {
  width: calc(100% - 200px);
}

.menuCon .rightMenu {
  float: right;
}

.barsMenu {
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: 8px;
  display: none;
  background: none;
}

/* .barsBtn {
  display: block;
  width: 20px;
  height: 2px;
  background: #8d96ff;
  position: relative;
}

.barsBtn:after,
.barsBtn:before {
  content: attr(x);
  width: 22px;
  position: absolute;
  top: -5px;
  left: 0;
  height: 2px;
  background: #8d96ff;
}

.barsBtn:after {
  top: auto;
  bottom: -6px;
} */
.barsBtn,
.barsBtn:after,
.barsBtn:before {
  display: block;
  width: 20px;
  height: 3px;
  background: black;
  /* background: #8d96ff; */
  position: relative;
}

.barsBtn:after,
.barsBtn:before {
  content: "";
  position: absolute;
  left: 0;
}

.barsBtn:before {
  top: -7px;
}

.barsBtn:after {
  bottom: -7.8px;
  height: 3.1px;
}

.ant-drawer-body {
  padding: 0;
}

.barsMenu > span {
  display: block;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item,
.ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu {
  display: inline-block;
  width: 100%;
}

.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover {
  border-bottom-color: transparent;
}

@media (max-width: 960px) {
  .barsMenu {
    display: inline-block;
  }

  .leftMenu,
  .rightMenu {
    display: none;
  }

  .logo a {
    margin-left: -20px;
  }

  .menuCon .ant-menu-item,
  .menuCon .ant-menu-submenu-title {
    padding: 1px 20px;
  }

  .logo a {
    padding: 10px 20px;
  }
}

.term_container > p {
  color: #151515;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26.7px;
  letter-spacing: -0.36px;
}

.anchor {
  text-decoration: underline;
  color: black;
}

.identifier > li {
  /* color: black; */
  font-family: Poppins;
  /* font-size: 20px; */
  /* font-style: normal; */
  font-weight: 500;
  line-height: 26.66px;
  letter-spacing: -0.2px;
}

.privacyTable thead > tr > th > p {
  color: black;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.66px;
  letter-spacing: -0.2px;
}

.textDetail {
  color: black;
  /* text-align: center; */
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.66px;
  letter-spacing: -0.2px;
}

.rsd__select-container-year,
.rsd__select-container-day,
.rsd__select-container-month {
  width: 100%;
  overflow: hidden;
  /* color: #ADADAD; */

  font-family: Poppins;
}

label{color:#000 !important}button,input,optgroup,select,textarea{margin-right:10px;position:relative;border:1px solid #c4dbf9;border-radius:.525rem;padding:10px;font-size:16px;width:100%}select:focus{border:1px solid #c4dbf9;outline:1px solid #c4dbf9}.rsd__select-container-day{margin-right:10px}.rsd__select-container-month{margin-right:10px}#select-form-container .ant-row{margin:1% 0 5%}#select-form-container .ant-row .ant-col .ant-form-item-control-input .ant-form-item-control-input-content .ant-select .ant-select-selector{border-radius:8px;height:50px;display:flex;align-items:center}#select-form-container .ant-row .ant-col .ant-form-item-control-input .ant-form-item-control-input-content .ant-select .ant-select-selector .ant-select-selection-placeholder{font-weight:400;font-size:14px;line-height:24px;color:rgba(29,37,45,.5019607843)}.registration-form-headings{margin-top:10px;font-weight:500;font-size:18px;line-height:24px;font-family:"Poppins"}@media(max-width: 961px){#businesstype{justify-content:space-between}#businesstype .rsd__select-container-day{width:33%}#businesstype .rsd__select-container-month{width:33%}#businesstype .rsd__select-container-year{width:33%}}
/* .sb-avatar__text {
    width: 60px !important;
    height: 60px !important;
} */
/* .tabstyle:hover {
    color: #8D96FF !important;
} */

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #8d96ff !important;
}

.ant-tabs-ink-bar {
  background-color: #8d96ff !important;
}

.ant-tabs-tab:hover {
  color: #8d96ff !important;
}

/* .ant-tabs-content-holder {
  margin: 0 30px;
} */

.carousel-container {
  position: relative;
  width: 600px;
  margin: 0 auto;
  border: 2px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  height: 280px;
}

.carousel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-slide {
  width: 100%;
  text-align: center;
  padding: 20px;
}

.carousel-content {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.dots-container {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  grid-gap: 5px;
  gap: 5px;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: lightgray;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: #8d96ff;
}

.centerTab {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  padding-top: 35px;
  grid-column-gap: 30px;
  column-gap: 30px;
  grid-row-gap: 20px;
  row-gap: 20px;
}

.partner {
  padding: 0 50px;
}

/* breakpoint for partner page */
@media (max-width: 800px) {
  .centerTab {
    grid-template-columns: auto;
    grid-row-gap: 30px;
  }
}

@media (max-width: 450px) {
  .partner {
    padding: 0 10px;
  }
}

/* .part1 {
    background-image: url('/img/pricing/bg.png');
} */
.pricing_container {
  display: flex;
  flex-wrap: wrap;
}

.pricing_box {
  flex: 50% 1;
  padding: 5px;
}

.pricing_box > h2 {
  color: black;
  /* text-align: center; */
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 45px;
}

.pricing_box > p {
  color: #2d2d2e;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

@media (max-width: 950px) {
  .cont {
    padding-left: 50px !important;
  }

  .headd {
    padding-left: 50px !important;
  }

  .robopic {
    width: 450px !important;
  }
}

@media (max-width: 600px) {
  .pricing_box {
    flex: 100% 1;
  }

  .robopic {
    width: 350px !important;
  }
}

.pricing_container {
  display: flex;
  flex-wrap: wrap;
}

.box1 {
  flex: 30% 1;
  padding-left: 85px;
  padding-top: 50px;
}

.box2 {
  flex: 70% 1;
  padding: 5px;
}

.box2 > h2 {
  color: black;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 72px;
}

.box2 > p {
  color: #2d2d2e;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.reviewImage {
  width: 850px;
  margin: auto;
}

.aboutUs {
  display: flex;
  flex-wrap: wrap;
}

.aboutBox {
  flex: 50% 1;
  padding: 25px;
  /* background-color: red; */
}

.about_innerBox {
  padding: 20px;
  /* background-color: #8189DB; */
  color: white;
}

.ceoImgCont {
  display: flex;
  flex-direction: column;
}

.ceoImg {
  display: flex;
  /* flex-direction: column; */
}

.str {
  display: flex;
  flex-direction: column;
  color: white;
  margin-left: 25px;
}

.str > a {
  color: white;
}

.imgDesc > p {
  color: #fff;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.remoteDiv {
  /* background-image: linear-gradient(to right, #a0a6ff, white);
    display: flex;
    flex-wrap: wrap; */
}

.remoteBox {
  flex: 50% 1;
}

@media (max-width: 550px) {
  .reviewImage {
    width: 385px;
  }

  .box1 > img {
    width: 260px !important;
  }

  .test2 {
    width: 300px !important;
  }

  .dots {
    display: none;
  }
}

@media (min-width: 550px) and (max-width: 950px) {
  .reviewImage {
    width: 550px;
  }
}

@media (max-width: 850px) {
  .remoteBox {
    flex: 100% 1;
    align-items: center;
    padding: 23px !important;
  }

  /* .test2 {
        width: 300px !important;
    } */
}

@media (max-width: 950px) {
  .cont {
    padding-left: 50px;
  }

  .box1,
  .box2 {
    flex: 100% 1;
  }

  .box2 {
    padding: 20px !important;
  }

  .aboutBox {
    flex: 100% 1;
  }

  .about_innerBox {
    width: 100% !important;
  }
}

@media (max-width: 1225px) {
  .box1 {
    text-align: center;
  }

  .headd {
    padding: 0 25px;
    text-align: center;
  }
}

@media (max-width: 1490px) {
  .box1 {
    /* text-align: center; */
    padding-left: 0 !important;
  }
}

.chatNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 0.7px solid #d6cfcf;
}

.chatNavBtn {
  width: auto;
  background-color: #8d96ff;
  color: white;
  letter-spacing: 0.5px;
  font-weight: 400;
  margin-right: 10px;
  cursor: pointer;
}

.chatNavContainer {
  display: flex;
  flex-wrap: wrap;
}

.chatNav1 {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;
  /* border: 2px solid red; */
  /* background-image: url("/new/chatNavbg.png"); */
}

.chatNav2 {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;
  /* border: 2px solid rgb(3, 126, 106); */
}

.chatnav2Inner {
  padding: 0 40px;
  background-color: #f0f1ff;
  height: auto;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  /* border-radius: 28px; */
  margin-top: 35px;
  border-top-left-radius: 28px;
  border-top-right-radius: 28px;
  justify-content: space-between;
  padding-bottom: 25px;
}

.chatLoginBtn {
  margin-right: 15px;
  width: auto;
  background-color: transparent;
  border: 2px solid #8d96ff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  padding: 0 20px;
  cursor: pointer;
}

.message {
  width: 60%;
  padding: 10px;
  /* margin: 10px; */
  border-radius: 10px;
}

.message > p {
  color: black;
  font-weight: 300;
  line-height: normal;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

/* .icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 5px;
    background-size: contain;
    background-repeat: no-repeat;
}

.icon.user {
    background-image: url('path-to-your-icon/user-icon.png');
}

.icon.receiver {
    background-image: url('path-to-your-icon/receiver-icon.png');
} */

.message-left {
  align-self: flex-start;
  /* background-color: #DCF8C6; */
  display: flex;
  align-items: center;
  width: auto;
  /* width: max-content; */
}

.message-right {
  align-self: flex-end;
  /* background-color: #FFFFFF; */
  display: flex;
  align-items: center;
  width: -webkit-max-content;
  width: max-content;
  /* border-top-left-radius: 28px; */
}

.message-left > p {
  border-top-right-radius: 28px;
  border-bottom-right-radius: 28px;
  border-bottom-left-radius: 28px;
}

.message-right > p {
  border-top-left-radius: 28px;
  border-bottom-right-radius: 28px;
  border-bottom-left-radius: 28px;
}

.chatInp:focus {
  /* background: #8d96ff; */
  outline: none;
  border: 2px solid #8d96ff;
}

.chatNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  /* border-bottom: 0.7px solid #d6cfcf; */
}

.chatNavBtn {
  width: auto;
  background-color: #8d96ff;
  color: white;
  letter-spacing: 0.5px;
  font-weight: 400;
  margin-right: 10px;
  cursor: pointer;
}

.chatNavContainer {
  display: flex;
  flex-wrap: wrap;
}

.chatNav1 {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;
  /* border: 2px solid red; */
  /* background-image: url("/new/chatNavbg.png"); */
}

.chatNav2 {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;
  /* border: 2px solid rgb(3, 126, 106); */
}

.chatnav2Inner {
  padding: 0 40px;
  background-color: #f0f1ff;
  height: auto;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  /* border-radius: 28px; */
  margin-top: 35px;
  border-top-left-radius: 28px;
  border-top-right-radius: 28px;
  justify-content: space-between;
  padding-bottom: 25px;
}

.chatLoginBtn {
  margin-right: 15px;
  width: auto;
  background-color: transparent;
  border: 2px solid #8d96ff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  padding: 0 20px;
  cursor: pointer;
}

.message {
  width: 60%;
  padding: 10px;
  /* margin: 10px; */
  border-radius: 10px;
}

.message > p {
  color: black;
  font-weight: 300;
  line-height: normal;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

/* .icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 5px;
    background-size: contain;
    background-repeat: no-repeat;
}

.icon.user {
    background-image: url('path-to-your-icon/user-icon.png');
}

.icon.receiver {
    background-image: url('path-to-your-icon/receiver-icon.png');
} */

.message-left {
  align-self: flex-start;
  /* background-color: #DCF8C6; */
  display: flex;
  align-items: center;
  width: auto;
  /* width: max-content; */
}

.message-right {
  align-self: flex-end;
  /* background-color: #FFFFFF; */
  display: flex;
  align-items: center;
  width: -webkit-max-content;
  width: max-content;
  /* border-top-left-radius: 28px; */
}

.message-left > p {
  border-top-right-radius: 28px;
  border-bottom-right-radius: 28px;
  border-bottom-left-radius: 28px;
}

.message-right > p {
  border-top-left-radius: 28px;
  border-bottom-right-radius: 28px;
  border-bottom-left-radius: 28px;
}

.chatInp:focus {
  /* background: #8d96ff; */
  outline: none;
  border: 2px solid #8d96ff;
}

.barsBtn,
.barsBtn:after,
.barsBtn:before {
  display: block;
  width: 20px;
  height: 3px;
  background: black;
  position: relative;
}

.barsBtn:after,
.barsBtn:before {
  content: "";
  position: absolute;
  left: 0;
}

.barsBtn:before {
  top: -7px;
}

.barsBtn:after {
  bottom: -7.8px;
  height: 3.1px;
}

.chatNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 0.7px solid #d6cfcf;
}

.chatNavBtn {
  width: auto;
  background-color: #8d96ff;
  color: white;
  letter-spacing: 0.5px;
  font-weight: 400;
  margin-right: 10px;
  cursor: pointer;
}

.chatNavContainer {
  display: flex;
  flex-wrap: wrap;
}

.chatNav1 {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;
  /* border: 2px solid red; */
  /* background-image: url("/new/chatNavbg.png"); */
}

.chatNav2 {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;
  /* border: 2px solid rgb(3, 126, 106); */
}

.chatnav2Inner {
  padding: 0 40px;
  background-color: #f0f1ff;
  height: auto;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  /* border-radius: 28px; */
  margin-top: 35px;
  border-top-left-radius: 28px;
  border-top-right-radius: 28px;
  justify-content: space-between;
  padding-bottom: 25px;
}

.chatLoginBtn {
  margin-right: 15px;
  width: auto;
  background-color: transparent;
  border: 2px solid #8d96ff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  padding: 0 20px;
  cursor: pointer;
}

.message {
  width: 60%;
  padding: 10px;
  /* margin: 10px; */
  border-radius: 10px;
}

.message > p {
  color: black;
  font-weight: 300;
  line-height: normal;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

/* .icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 5px;
    background-size: contain;
    background-repeat: no-repeat;
}

.icon.user {
    background-image: url('path-to-your-icon/user-icon.png');
}

.icon.receiver {
    background-image: url('path-to-your-icon/receiver-icon.png');
} */

.message-left {
  align-self: flex-start;
  /* background-color: #DCF8C6; */
  display: flex;
  align-items: center;
  width: auto;
  /* width: max-content; */
}

.message-right {
  align-self: flex-end;
  /* background-color: #FFFFFF; */
  display: flex;
  align-items: center;
  width: -webkit-max-content;
  width: max-content;
  /* border-top-left-radius: 28px; */
}

.message-left > p {
  border-top-right-radius: 28px;
  border-bottom-right-radius: 28px;
  border-bottom-left-radius: 28px;
}

.message-right > p {
  border-top-left-radius: 28px;
  border-bottom-right-radius: 28px;
  border-bottom-left-radius: 28px;
}

.chatInp:focus {
  /* background: #8d96ff; */
  outline: none;
  border: 2px solid #8d96ff;
}

.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

* {
  font-family: "Poppins";
}

*:focus {
  /* outline: #8d96ff; */
  border-color: #8d96ff !important;
}

.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading .ant-spin {
  color: #3e79f7;
}

.steps-content {
  min-height: 250px;
  margin-top: 20px;
  padding: 20px;
  background-color: #fafafa;
  border: 1px dashed #e9e9e9;
  border-radius: 5px;
}

.steps-action {
  margin-top: 20px;
  text-align: center;
}

.steps-action .btn {
  margin-right: 10px;
  width: 200px;
}

/* index.css */

/* Regular */
@font-face {
  font-family: "Poppins";
  src: url(/static/media/Poppins-Regular.8081832f.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Bold */
@font-face {
  font-family: "Poppins";
  src: url(/static/media/Poppins-Bold.404e299b.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
}

/* Italic */
@font-face {
  font-family: "Poppins";
  src: url(/static/media/Poppins-Italic.ce3fff31.ttf) format("truetype");
  font-weight: normal;
  font-style: italic;
}

/* Semi-Bold */
@font-face {
  font-family: "Poppins";
  src: url(/static/media/Poppins-SemiBold.cce5625b.ttf) format("truetype");
  font-weight: 600;
  /* Semi-Bold weight */
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url(/static/media/Poppins-Medium.9e1bb626.ttf) format("truetype");
  font-weight: 500;
  /* Medium weight */
  font-style: normal;
}

/* Add more variations as needed */

.ant-layout {
  background-color: white !important;
}

.ant-picker-header {
  width: -webkit-max-content;
  width: max-content;
  /* padding: 5px; */
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background-color: #8d96ff;
  color: white;
  border: none;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  /* background-color: #8D96FF; */
  color: #8d96ff;
  border: none;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: white;
  border-color: #8d96ff;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #8d96ff;
}

/* body {
  height: 100%;
}

#root {
  height: 100%;
}

.App {
  height: 100%;
} */

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background-color: #8d96ff;
  border: none;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #8d96ff;
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #8d96ff;
}

.custom-modal .ant-modal-content {
  border-radius: 24px;
}

.custom-modal .ant-modal-body {
  border-radius: 24px;
}

/* WebKit browsers */
.custom-modal .ant-modal-body::-webkit-scrollbar {
  width: 8px;
}

.custom-modal .ant-modal-body::-webkit-scrollbar-track {
  background: transparent;
}

.custom-modal .ant-modal-body::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  /* Semi-transparent scrollbar thumb */
  border-radius: 4px;
}

/* Firefox */
.custom-modal .ant-modal-body {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

p {
  color: black;
  font-weight: 400;
  margin: 0;
}

.active-menu-item {
  /* border-bottom: 2px solid black; */
  text-decoration: underline;
  font-weight: 600;
}

/* WebKit browsers */
.custom-steps ::-webkit-scrollbar {
  width: 8px;
}

.custom-steps ::-webkit-scrollbar-track {
  background: transparent;
}

.custom-steps ::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  /* Semi-transparent scrollbar thumb */
  border-radius: 4px;
}


.carousel-container {
  position: relative;
  width: 600px;
  margin: 0 auto;
  border: 2px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  height: 280px;
}

.carousel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-slide {
  width: 100%;
  text-align: center;
  padding: 20px;
}

.carousel-content {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.dots-container {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 5px;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: lightgray;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: #8d96ff;
}

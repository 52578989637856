.centerTab {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  padding-top: 35px;
  column-gap: 30px;
  row-gap: 20px;
}

.partner {
  padding: 0 50px;
}

/* breakpoint for partner page */
@media (max-width: 800px) {
  .centerTab {
    grid-template-columns: auto;
    grid-row-gap: 30px;
  }
}

@media (max-width: 450px) {
  .partner {
    padding: 0 10px;
  }
}

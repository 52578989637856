/* .sb-avatar__text {
    width: 60px !important;
    height: 60px !important;
} */
/* .tabstyle:hover {
    color: #8D96FF !important;
} */

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #8d96ff !important;
}

.ant-tabs-ink-bar {
  background-color: #8d96ff !important;
}

.ant-tabs-tab:hover {
  color: #8d96ff !important;
}

/* .ant-tabs-content-holder {
  margin: 0 30px;
} */

.pricing_container {
  display: flex;
  flex-wrap: wrap;
}

.box1 {
  flex: 30%;
  padding-left: 85px;
  padding-top: 50px;
}

.box2 {
  flex: 70%;
  padding: 5px;
}

.box2 > h2 {
  color: black;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 72px;
}

.box2 > p {
  color: #2d2d2e;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.reviewImage {
  width: 850px;
  margin: auto;
}

.aboutUs {
  display: flex;
  flex-wrap: wrap;
}

.aboutBox {
  flex: 50%;
  padding: 25px;
  /* background-color: red; */
}

.about_innerBox {
  padding: 20px;
  /* background-color: #8189DB; */
  color: white;
}

.ceoImgCont {
  display: flex;
  flex-direction: column;
}

.ceoImg {
  display: flex;
  /* flex-direction: column; */
}

.str {
  display: flex;
  flex-direction: column;
  color: white;
  margin-left: 25px;
}

.str > a {
  color: white;
}

.imgDesc > p {
  color: #fff;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.remoteDiv {
  /* background-image: linear-gradient(to right, #a0a6ff, white);
    display: flex;
    flex-wrap: wrap; */
}

.remoteBox {
  flex: 50%;
}

@media (max-width: 550px) {
  .reviewImage {
    width: 385px;
  }

  .box1 > img {
    width: 260px !important;
  }

  .test2 {
    width: 300px !important;
  }

  .dots {
    display: none;
  }
}

@media (min-width: 550px) and (max-width: 950px) {
  .reviewImage {
    width: 550px;
  }
}

@media (max-width: 850px) {
  .remoteBox {
    flex: 100%;
    align-items: center;
    padding: 23px !important;
  }

  /* .test2 {
        width: 300px !important;
    } */
}

@media (max-width: 950px) {
  .cont {
    padding-left: 50px;
  }

  .box1,
  .box2 {
    flex: 100%;
  }

  .box2 {
    padding: 20px !important;
  }

  .aboutBox {
    flex: 100%;
  }

  .about_innerBox {
    width: 100% !important;
  }
}

@media (max-width: 1225px) {
  .box1 {
    text-align: center;
  }

  .headd {
    padding: 0 25px;
    text-align: center;
  }
}

@media (max-width: 1490px) {
  .box1 {
    /* text-align: center; */
    padding-left: 0 !important;
  }
}

.term_container > p {
  color: #151515;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26.7px;
  letter-spacing: -0.36px;
}

.anchor {
  text-decoration: underline;
  color: black;
}

.identifier > li {
  /* color: black; */
  font-family: Poppins;
  /* font-size: 20px; */
  /* font-style: normal; */
  font-weight: 500;
  line-height: 26.66px;
  letter-spacing: -0.2px;
}

.privacyTable thead > tr > th > p {
  color: black;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.66px;
  letter-spacing: -0.2px;
}

.textDetail {
  color: black;
  /* text-align: center; */
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.66px;
  letter-spacing: -0.2px;
}

.rsd__select-container-year,
.rsd__select-container-day,
.rsd__select-container-month {
  width: 100%;
  overflow: hidden;
  /* color: #ADADAD; */

  font-family: Poppins;
}
